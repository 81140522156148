
// Body
// $body-bg: #E9E9E9;

// Typography
$font-family-sans-serif: 'Montserrat', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// My custom
// scss-docs-start theme-color-variables
$primaryColor: #E8A44A;
$bgSecondaryColor: #D0D0D0;
$bgDarkColor: #222222;

// Tabela
$bgTheadTable: #BABABA;
$bgTbodyTable: #FFF;

$primary:  $primaryColor !default;
$secondary: $bgSecondaryColor !default;
$dark: $bgDarkColor !default;

// Popover
$popover-max-width: calc(100% - 10px);
$popover-bg: $bgTheadTable !default;