// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Icons
@import '~bootstrap-icons/font/bootstrap-icons.css';

// add these:
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";


body {
    background-color: #E9E9E9;
}

fieldset {
    border: 1px solid #EEEEEE;
    background: #FFF;
    margin-top: 10px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
}

legend { font-size: 80% }

table > thead { background-color: $bgTheadTable; }
table > tbody { background-color: $bgTbodyTable; }

table > thead > tr > th {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
    text-align: center;
    font-size: 80%;
}

// Popover
.custom-popover {
    display: flex;
    flex-direction: column;
    min-width: 300px;
}
.custom-popover > div {
    display: flex;
    justify-content: space-between;
}

.hidden {
    display: none;
}
